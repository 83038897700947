const initialState = {
  cardData: [],
  totalPrice: 0,
  totalCounts: 0,
};

const cardItems = (state = initialState, { type, data, count }) => {
  switch (type) {
    case "ADD_TO_CART":
      let addItem = data;
      let existedItem = state.cardData.find((item) => item.id === data.id);
      if (existedItem) {
        existedItem.quantity += 1;
        localStorage.setItem("cart", {
          cardData: state.cardData,
          totalPrice: state.totalPrice + addItem.price,
          totalCounts: state.totalCounts + 1,
        });
        localStorage.setItem(
          "cart",
          JSON.stringify({
            cardData: state.cardData,
            totalPrice: state.totalPrice + addItem.discountedPrice,
            totalCounts: state.totalCounts + 1,
          })
        );
        return {
          ...state,
          cardData: state.cardData,
          totalCounts: state.totalCounts + 1,
          totalPrice: state.totalPrice + addItem.totalPrice,
        };
      } else {
        let temp = { ...addItem, quantity: count ? count : 1 };
        localStorage.setItem(
          "cart",
          JSON.stringify({
            cardData: state.cardData,
            totalPrice: state.totalPrice + addItem.price,
            totalCounts: state.totalCounts + 1,
          })
        );
        return {
          ...state,
          cardData: [...state.cardData, temp],
          totalCounts: state.totalCounts + 1,
          totalPrice: state.totalPrice + addItem.price,
        };
      }
    case "REMOVE_TO_CART":
      let removeItem = state.cardData.find((item) => item.id === data.id);
      let newItems = state.cardData.filter((item) => item.id !== data.id);
      let newTotal = state.totalPrice - removeItem.price * removeItem.quantity;
      localStorage.setItem("cart", {
        cardData: newItems,
        totalCounts: state.totalCounts - removeItem.quantity,
        totalPrice: newTotal,
      });
      return {
        ...state,
        cardData: newItems,
        totalCounts: state.totalCounts - removeItem.quantity,
        totalPrice: state.totalPrice - removeItem.price * removeItem.quantity,
      };
    case "ADD_QUANTITY":
      let addedItem = state.cardData.find((item) => item.id === data.id);
      addedItem.quantity += 1;
      localStorage.setItem("cart", {
        cardData: state.cardData,
        totalCounts: state.totalCounts + 1,
        totalPrice: state.totalCounts + addedItem.discountedPrice,
      });
      return {
        ...state,
        totalCounts: state.totalCounts + 1,
        totalPrice: state.totalPrice + addedItem.discountedPrice,
      };
    case "SUB_QUANTITY":
      let subItem = state.cardData.find((item) => item.id === data.id);
      if (subItem.quantity === 1) {
        let newSubItem = state.cardData.filter((item) => item.id !== data.id);
        localStorage.setItem("cart", {
          cardData: newSubItem,
          totalPrice: state.totalPrice - subItem.discountedPrice * subItem.quantity,
          totalCounts: state.totalCounts - 1,
        });
        return {
          ...state,
          cardData: newSubItem,
          totalCounts: state.totalCounts - 1,
          totalPrice: state.totalPrice - subItem.discountedPrice * subItem.quantity,
        };
      } else {
        subItem.quantity -= 1;
        localStorage.setItem("cart", {
          cardData: state.cardData,
          totalCounts: state.totalCounts - 1,
          totalPrice: state.totalPrice - subItem.discountedPrice * subItem.quantity,
        });
        return {
          ...state,
          totalCounts: state.totalCounts - 1,
          totalPrice: state.totalPrice - subItem.discountedPrice * subItem.quantity,
        };
      }

    case "CLEAR_CART":
      state = initialState;
      return state;

    default:
      return state;
  }
};

export default cardItems;
