import React from "react";
import { NavLink } from "react-router-dom";

const Cancellations = () => {
  return (
    <div className="edit-profile-main">
      <h3>My Cancellations</h3>
      <div className="edit-profile-content">
        <div className="container">
          <div className="name-field">
            <p>There are no cancellations yet</p>
            <div className="profile-changes-btn">
              <NavLink to="/">
                <button>Continue Shopping</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cancellations;
