import React from "react";
import { NavLink } from "react-router-dom";

const AddressInfo = () => {
  return (
    <div className="edit-profile-main">
      <h3>Address Information</h3>
      <div className="edit-profile-content">
        <div className="container">
          <p>Save your shipping and billing address here...</p>
          <div className="profile-changes-btn">
            <NavLink to="/profile/addressinformationform">
              <button>+ Add New Address</button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressInfo;
