import axios from "axios";
import React, { useEffect, useState } from "react";

const Secondbanner = () => {
  const [ads, setAds] = useState([]);
  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/advertisment/get`,
    }).then((res) => setAds(res.data.data));
  }, []);
  return (
    <div className="sec-banner">
      <div className="container">
        {ads.slice(0, 1).map((item) => {
          return (
            <div className="second-banner-img">
              <img src={item.image} alt="second banner" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Secondbanner;
