import React from "react";
import { Routes, Route } from "react-router-dom";
import AddressInfo from "../EditProfilePages/AddressInfo/AddressInfo";
import AddressInfoForm from "../EditProfilePages/AddressInfo/AddressInfoForm";
import EditProfile from "../EditProfilePages/EditProfile";
import PaymentOptions from "../EditProfilePages/PaymentOptions/PaymentOptions";
import RightSideBar from "../RightSideBar";
import Voucher from "../EditProfilePages/Voucher/Voucher";
import Return from "../EditProfilePages/Return/Return";
import Cancellations from "../EditProfilePages/Cancellations/Cancellations";
import Review from "../EditProfilePages/Reviews/Review";
import Wishlist from "../EditProfilePages/Wishlist/Wishlist";
import OrderHistory from "../EditProfilePages/OrderHistory/OrderHistory";
import OrderList from "../EditProfilePages/OrderHistory/OrderList";
const ProfileRouting = (props) => {
  return (
    <Routes>
      <Route path="/" element={<EditProfile user={props.user} />} />
      {/* <Route path="/editprofile" element={<EditProfile user={props.user} />} /> */}
      <Route path="/addressinformation" element={<AddressInfo />} />
      <Route path="/addressinformationform" element={<AddressInfoForm />} />
      <Route path="/paymentoptions" element={<PaymentOptions />} />
      <Route path="/voucher" element={<Voucher />} />
      <Route path="/myreturns" element={<Return />} />
      <Route path="/cancellations" element={<Cancellations />} />
      <Route path="/myreview" element={<Review />} />
      <Route path="/mywishlist" element={<Wishlist />} />
      <Route path="/myorderhistory" element={<OrderList user={props.user} />} />
    </Routes>
  );
};

export default ProfileRouting;
