export const addToWishlist = (data, count) => {
  return {
    type: "ADD_TO_WISHLIST",
    data: data,
    count: count,
  };
};
export const removeToWishlist = (id) => {
  return {
    type: "REMOVE_TO_WISHLIST",
    data: id,
  };
};
