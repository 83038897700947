const initialData = {
  wishedData: [],
  totalCounts: 0,
};
const cardWished = (state = initialData, { type, data }) => {
  switch (type) {
    case "ADD_TO_WISHLIST":
      let addedItem = data;
      let existedItem = state.wishedData.find((item) => item.id === data.id);
      if (existedItem) {
        existedItem.quantity = existedItem.quantity;
        return {
          ...state,
          wishedData: state.wishedData,
          totalCounts: state.totalCounts,
        };
      } else {
        let temp = { ...addedItem, quantity: 1 };
        return {
          ...state,
          wishedData: [...state.wishedData, temp],
          totalCounts: state.totalCounts + 1,
        };
      }

    case "REMOVE_TO_WISHLIST":
      let newItems = state.wishedData.filter((item) => item.id !== data.id);
      localStorage.setItem("cart", {
        wishedData: newItems,
        totalCounts: state.totalCounts - 1,
      });
      return {
        ...state,
        wishedData: newItems,
        totalCounts: state.totalCounts - 1,
      };

    default:
      return state;
  }
};
export default cardWished;
