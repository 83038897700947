import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import Skeleton from "@mui/material/Skeleton";
import { ValidEmail, ValidContact } from "../../../helpers/Validators";

const EditProfile = ({ user }) => {
  const navigate = useNavigate();
  const [loadingPersonalData, setLoadingPersonalData] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const initialState = {
    name: "",
    email: "",
    phone_no: "",
    dateofbirth: "",
    address: "",
  };

  const [validationMessage, setValidationMessage] = useState({
    name: null,
    email: null,
    phone_no: null,
    dateofbirth: null,
    address: null,
  });

  const [profileData, setProfileData] = useState(initialState);

  const logInId = user?.user.id;

  const closeModal = () => {
    document.querySelector("#close-button").click();
  };

  useEffect(() => {
    setLoadingPersonalData(true);
    axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
      url: `${process.env.REACT_APP_API_URL}/user/get/${logInId}`,
    })
      .then((res) => {
        let response = res.data.data.user;
        setProfileData(response);
        setLoadingPersonalData(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingPersonalData(false);
      });
  }, [user]);

  const updateInputHandler = (e) => {
    e.preventDefault();
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    success: null,
    error: null,
  });

  const updateProfile = () => {
    if (profileData?.name === "") {
      setValidationMessage({
        name: "Name cannot be empty",
        email: null,
        phone_no: null,
        dateofbirth: null,
        address: null,
      });
      return;
    } else if (profileData?.email === "") {
      setValidationMessage({
        name: null,
        email: "Email cannot be empty",
        phone_no: null,
        dateofbirth: null,
        address: null,
      });
      return;
    } else if (ValidEmail(profileData?.email) === false) {
      setValidationMessage({
        name: null,
        email: "Invalid email address",
        phone_no: null,
        dateofbirth: null,
        address: null,
      });
      return;
    } else if (profileData?.phone_no === "") {
      setValidationMessage({
        name: null,
        email: null,
        phone_no: "Phone number cannot be empty",
        dateofbirth: null,
        address: null,
      });
      return;
    } else if (ValidContact(profileData?.phone_no) === false) {
      setValidationMessage({
        name: null,
        email: null,
        phone_no: "Invalid contact number",
        dateofbirth: null,
        address: null,
      });
      return;
    } else if (profileData?.dateofbirth === "") {
      setValidationMessage({
        name: null,
        email: null,
        phone_no: null,
        dateofbirth: "Date of birth cannot be empty",
        address: null,
      });
      return;
    } else if (profileData?.address === "") {
      setValidationMessage({
        name: null,
        email: null,
        phone_no: null,
        dateofbirth: null,
        address: "Address cannot be null",
      });
      return;
    } else {
      setValidationMessage({
        name: null,
        email: null,
        phone_no: null,
        dateofbirth: null,
        address: null,
      });

      setLoading(true);
      axios({
        method: "POST",
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
        url: `${process.env.REACT_APP_API_URL}/user/update`,
        data: profileData,
      })
        .then((res) => {
          console.log("update profile", res.data);

          enqueueSnackbar(`Profile has been updated successfully`, {
            variant: "success",
          });
          closeModal();
          // setResponse({
          //   success: "profile successfully updated",
          //   error: null,
          // });
          navigate("/profile");
          setLoading(false);
          // $('#exampleModalLabel').modal('hide');
        })
        .catch((err) => {
          setResponse({
            success: null,
            error: "server Error !!",
          });
          enqueueSnackbar(`Failed to update profile`, {
            variant: "error",
          });
          setLoading(false);
        });
    }
  };

  console.log("user ko id", user);

  return (
    <div className="edit-profile-main">
      <h3>My Profile</h3>
      <div className="edit-profile-content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="name-field">
                <label htmlFor="">Full Name</label>
                <br />
                <p>{loadingPersonalData ? <Skeleton variant="text" width="100%" /> : profileData?.name}</p>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <p>Email Address</p>
              <p> {loadingPersonalData ? <Skeleton variant="text" width="100%" /> : profileData?.email}</p>
            </div>
            <div className="col-md-6">
              <div className="name-field">
                <label htmlFor="">Mobile</label>
                <br />
                {loadingPersonalData ? <Skeleton variant="text" width="100%" /> : profileData?.phone_no}
              </div>
            </div>
            <div className="col-md-6">
              <div className="name-field">
                <label htmlFor="">Birthday Date</label>
                <br />
                {profileData?.dateofbirth ? (
                  <p>{profileData.dateofbirth}</p>
                ) : (
                  <button type="button" className="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Add DOB
                  </button>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="name-field">
                <label htmlFor="">Address</label>
                <br />
                {profileData?.address ? (
                  <p>{profileData.address}</p>
                ) : (
                  <button type="button" className="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Add Address
                  </button>
                )}
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
          <div className="profile-changes-btn">
            <button type="button" className="button" data-bs-toggle="modal" data-bs-target="#exampleModal">
              Edit Profile
            </button>
          </div>

          {/* Profile Modal */}

          <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Edit profile
                  </h5>
                  <button id="close-button" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div classname="">
                      <label for="exampleInputname" classname="form-label">
                        Name
                      </label>
                      <br />
                      <input
                        type="text"
                        classname="form-control"
                        id="exampleInputname"
                        name="name"
                        value={profileData?.name}
                        onChange={updateInputHandler}
                        onKeyPress={(event) => {
                          if (!/[A-Z a-z]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        style={
                          validationMessage.name
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                      {validationMessage.name ? <span className="error error-message">{validationMessage.name}</span> : null}
                    </div>
                    <div classname="mt-3">
                      <label for="exampleInputEmail1" classname="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        classname="form-control"
                        id="exampleInputEmail1"
                        name="email"
                        value={profileData?.email}
                        onChange={updateInputHandler}
                        style={
                          validationMessage.email
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                      {validationMessage.email ? <span className="error error-message">{validationMessage.email}</span> : null}
                    </div>
                    <div classname="mt-3">
                      <label for="exampleInputnumber" classname="form-label">
                        Contact Number
                      </label>
                      <input
                        type="number"
                        classname="form-control"
                        id="exampleInputnumber"
                        name="phone_no"
                        value={profileData?.phone_no}
                        onChange={updateInputHandler}
                        style={
                          validationMessage.phone_no
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                    </div>
                    {validationMessage.phone_no ? <span className="error error-message">{validationMessage.phone_no}</span> : null}

                    <div classname="mt-3">
                      <label for="exampleInputnumber" classname="form-label">
                        DOB
                      </label>
                      <input
                        type="date"
                        classname="form-control"
                        id="exampleInputnumber"
                        aria-describedby="numberHelp"
                        name="dateofbirth"
                        value={profileData?.dateofbirth}
                        onChange={updateInputHandler}
                        max={new Date().toISOString().split("T")[0]}
                        style={
                          validationMessage.dob
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                      {validationMessage.dob ? <span className="error error-message">{validationMessage.dob}</span> : null}
                    </div>
                    <div classname="mt-3">
                      <label for="exampleInputnumber" classname="form-label">
                        Address
                      </label>
                      <input
                        type="text"
                        classname="form-control"
                        id="exampleInputnumber"
                        aria-describedby="numberHelp"
                        name="address"
                        value={profileData?.address}
                        onChange={updateInputHandler}
                        style={
                          validationMessage.address
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                      {validationMessage.address ? <span className="error error-message">{validationMessage.address}</span> : null}
                    </div>
                  </form>
                </div>

                <div className="modal-footer profile-changes-btn">
                  <LoadingButton type="button" loading={loading} className="button" onClick={updateProfile}>
                    Save changes
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>

          {/* Password Change Modal */}
          {/* <div className="profile-changes-btn">
            <button
              type="button"
              className="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
            >
              Change Password
            </button>
          </div> */}
          <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    change password
                  </h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label">
                        old Password
                      </label>
                      <input type="password" className="form-control" id="exampleInputPassword1" />
                    </div>
                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label">
                        new Password
                      </label>
                      <input type="password" className="form-control" id="exampleInputPassword1" />
                    </div>
                    <div class="mb-3">
                      <label for="exampleInputPassword1" class="form-label">
                        confirm Password
                      </label>
                      <input type="password" className="form-control" id="exampleInputPassword1" />
                    </div>
                  </form>
                </div>
                <div className="modal-footer profile-changes-btn">
                  <button type="button" className="button">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
