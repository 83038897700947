import axios from "axios";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";

const Whychooseus = () => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: data.length > 4 ? 4 : data.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: data.length > 4 ? 4 : data.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: data.length > 2 ? 2 : data.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/choose/get`,
    })
      .then((res) => {
        setData(res.data.data);
        setLoading(true);
      })
      .catch((e) => console.log(e.message));
  }, []);

  return (
    <>
      {data.length > 0 ? (
        <>
          <div className="choose-main">
            <div className="container">
              <div className="main-topic">
                <p>
                  Why <span>Choose Us ?</span>
                </p>
              </div>
            </div>
          </div>

          {/* Section */}

          <div className="main-banner">
            <div className="container">
              <div className="category-main">
                <div className="row">
                  {loading ? (
                    <Slider {...settings} className="slider-main">
                      {data.map((item, id) => (
                        <div className="col-md-2">
                          <div className="category-img">
                            <img src={item.image} alt="image" />
                            <p>{item.title}</p>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Whychooseus;
