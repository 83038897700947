import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Button, CircularProgress } from "@mui/material";
import OrderLoader from "./OrderLoader";
export default function OrderList({ user }) {
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [cancelLoading, setCancelLoading] = useState(false);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    setLoading(true);

    if (user) {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/order/orders-history`,
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      })
        .then((res) => {
          setAllOrders(res.data.data.Orders);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e.message);
          setLoading(false);
        });
    }
  }, [user, reload]);

  const closeModal = () => {
    document.querySelector("#close-button").click();
  };

  const cancelOrder = async () => {
    setCancelLoading(true);
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/order/cancelorder/${selectedOrder?.id}`,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    })
      .then((res) => {
        if (res.data.status) {
          enqueueSnackbar("Your order has been canceled. ", {
            variant: "success",
          });
          setReload(!reload);

          closeModal();
        } else {
          enqueueSnackbar("Failed to cancel order.", {
            variant: "error",
          });
        }
      })
      .catch((e) => {
        enqueueSnackbar("Failed to cancel order.", {
          variant: "error",
        });
      });

    setCancelLoading(false);
  };

  return (
    <>
      <div className="" style={{ overflowX: "auto" }}>
        <h3 className="mb-3">My Orders</h3>

        <table className="table order-table">
          <thead>
            <tr>
              <th scope="col">S.N</th>
              <th scope="col">Order ID</th>
              <th scope="col">Date</th>
              <th scope="col">Amount</th>
              <th scope="col">Status</th>

              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <OrderLoader />
            ) : (
              allOrders.map((item, id) => (
                <tr>
                  <th scope="row">{id + 1}</th>
                  <td>{item.order_no}</td>
                  <td>{item.created_at.split("T")[0]}</td>
                  <td>${item.amount}</td>
                  <td>{item.status === "pending" ? <span className="badge bg-warning">{item.status}</span> : item.status === "canceled" ? <span className="badge bg-danger">{item.status}</span> : <span className="badge bg-success">{item.status}</span>}</td>
                  <td>
                    <a href />
                    {/* Button trigger modal */}
                    <button
                      type="button"
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      onClick={(e) => {
                        setSelectedItem(item.order_items);
                        setSelectedOrder(item);
                      }}>
                      <span>
                        <i className="fa-solid fa-eye" />
                      </span>
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        {/* Modal */}
        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content container">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Creatu
                </h5>
                <button type="button" id="close-button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div>
                  <h5 className="order">Thanks for your order</h5>
                </div>
                {/* <h6 className="order">Payment summary</h6> */}
                <hr />
              </div>

              <h5 className="mb-3 text-center">Ordered Items</h5>
              <table className="table table-striped order-list-table">
                <thead>
                  <tr>
                    <td>S.N</td>
                    <td>Item</td>

                    <td>Quantity</td>
                    <td>Unit Price</td>
                    <td>Total</td>
                  </tr>
                </thead>
                <tbody>
                  {selectedItem.map((item, id) => (
                    <tr>
                      <td>{id + 1}</td>
                      <td>{item.product?.name}</td>
                      <td>{item.qty}</td>
                      <td>${item.product?.price}</td>
                      <td>${parseInt(item.qty) * parseInt(item.product?.price)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <div className="row order-summary">
                <div className="col-md-1">
                  <h6>S.N</h6>
                  <p>1</p>
                </div>
                <div className="col-md-3">
                  <h6> Item</h6>
                  <p>Mobile</p>
                </div>
                <div className="col-md-3">
                  <h6> Quantity</h6>2
                </div>
                <div className="col-md-2">
                  <h6> Unit</h6>1
                </div>
                <div className="col-md-3">
                  <h6> total</h6>
                  <p>$2000</p>
                </div>
              </div> */}

              <div className="modal-footer">
                {selectedOrder?.status === "pending" ? (
                  cancelLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button color="primary" variant="contained" size="small" type="button" onClick={cancelOrder}>
                      Cancel Your Order
                    </Button>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
