// reducers/uiReducer.js
const initialState = {
  successSnackbarOpen: false,
  successSnackbarMessage: "",
  errorSnackbarOpen: false,
  infoSnackbarOpen: false,
  reload: true,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SNACKBAR_SUCCESS":
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.message,
        reload: !state.reload,
      };
    case "SNACKBAR_CLEAR":
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
      };
    default:
      return state;
  }
};

export default uiReducer;
