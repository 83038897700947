import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
export default function BannerLoader() {
  return (
    <>
      <Stack spacing={1}>
        <Skeleton variant="rectangle" height="30rem" />
      </Stack>
    </>
  );
}
