import React from "react";
import ReactImageMagnify from "react-image-magnify";

const ZoomImage = ({ image }) => {
  console.log(image);
  return (
    <div>
      <div>
        <ReactImageMagnify
          {...{
            smallImage: {
              alt: "zooming ",
              isFluidWidth: true,
              src: image,
              height: 400,
              width: 400,
            },
            largeImage: {
              src: image,
              width: 1200,
              height: 1800,
            },
          }}
        />
      </div>
    </div>
  );
};

export default ZoomImage;
