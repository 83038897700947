import React from "react";
import { NavLink } from "react-router-dom";

const Return = () => {
  return (
    <div className="edit-profile-main">
      <h3>My Returns</h3>
      <div className="edit-profile-content">
        <div className="container">
          <div className="name-field">No Returns Yet</div>
          <NavLink to="/">
            <div className="profile-changes-btn">
              <button>Continue Shopping</button>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Return;
