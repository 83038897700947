import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { removeToWishlist } from "../../../../Services/Action/WishlistAction";
import { Link } from "react-router-dom";
import { addToCart } from "../../../../Services/Action/Action";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";

const Wishlist = ({ user }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getWishdata = useSelector((state) => state.cardWished);

  console.log(getWishdata);

  const sendToCart = (e, get) => {
    dispatch(addToCart(get));
    dispatch(removeToWishlist(get));

    enqueueSnackbar("Item moved to cart", {
      variant: "success",
    });
  };

  const removeWish = (e, get) => {
    e.preventDefault();
    dispatch(removeToWishlist(get));
  };

  if (getWishdata?.wishedData?.length > 0) {
    return (
      <div className="cart-main">
        <div className="container">
          <div className="main-topic">
            <p>Wishlist</p>
          </div>
          <form className="cart-form">
            <table className="cart-table">
              <thead>
                <tr>
                  <th className="remove"></th>
                  <th className="product-img">PRODUCT IMAGE</th>
                  <th className="product-name">PRODUCT NAME</th>
                  <th className="unitprice">UNIT PRICE</th>
                  <th className="unitprice">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {getWishdata.wishedData
                  ? getWishdata.wishedData.map((get, keys) => {
                      return (
                        <>
                          <tr>
                            <td className="remove">
                              <i onClick={(e) => removeWish(e, get)} class="fa-solid fa-xmark"></i>
                            </td>
                            <td className="product-img">
                              <img src={get.image} alt="cart photoo" />
                            </td>
                            <td className="product-name">
                              <p> {get.name} </p>
                            </td>
                            <td className="unitprice">
                              <p>$ {get.price}</p>
                            </td>
                            <td className="cartbutton">
                              <Button color="primary" variant="contained" size="small" className="btn btn-sm ml-2" onClick={(e) => sendToCart(e, get)}>
                                Move to cart
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </form>
          {/* <div className="subtotal-main">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <div className="subtotal">
                  <div className="row">
                    <div className="col-md-4 col-6 subtotal-mob">Subtotal</div>
                    <div className="col-md-4 col-0"></div>
                    <div className="col-md-4 col-6 ">$ {total} /-</div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 subtotal-mob">Shipping Fee</div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4">$ 200 /-</div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-4 total-cart">TOTAL</div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4 total-cart">$ {total}</div>
                  </div>
                  <div className="checkout">
                    <Link to={user ? "/checkout" : "/login"}>
                      <button>Check Out</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="cart-main">
          <div className="container">
            <div className="main-topic button">
              <p>Wishlist</p>
              <p>Wishlist is empty</p>
              <Link to="/" className="btn">
                Go Shopping
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Wishlist;
