import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Login from "../Pages/Login";
import Signup from "../Pages/Signup";
import Resetpassword from "../Pages/Resetpassword";
import Detailpage from "../Pages/DetailPage/Detailpage";
import Cart from "../Pages/Cart/Cart";
import Termsandconditions from "../Pages/Termsandconditions/Termsandconditions";
import CategoriesContent from "../Pages/CategoriesContent/CategoriesContent";
import Signupverification from "../Pages/Signupverification/Signupverification";
import Codeverification from "../Pages/Codeverification/Codeverification";
import ConfirmNewPassword from "../Pages/ConfirmNewPassword/ConfirmNewPassword";
import Profile from "../Pages/Profile/Profile";
import { Contact } from "../Pages/Contact";
import About from "../Pages/About/About";
import Checkout from "../Pages/Checkout/Checkout";
import Payment from "../Pages/Checkout/Payment";
import Wishlist from "../Pages/Profile/EditProfilePages/Wishlist/Wishlist";
import FilterPage from "../Pages/Filter/FilterPage";
import Privacy from "../Pages/Privacy/Privacy";
import Return from "../Pages/ReturnPolicy/Return";

const Homerouting = (props) => {
  const [email, setEmail] = useState("");
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/product/:slug" element={<Detailpage />} />
      <Route exact path="/:category_slug/:slug" element={<Detailpage />} />

      <Route exact path="/cart" element={<Cart user={props.user} />} />

      <Route exact path="/wishlist" element={<Wishlist user={props.user} />} />

      <Route path="/login" element={<Login user={props.user} setUser={props.setUser} />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/passwordreset" element={<Resetpassword email={email} setEmail={setEmail} />} />
      <Route path="/termsandconditions" element={<Termsandconditions />} />

      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/return-policy" element={<Return />} />

      <Route path="/category/:slug" element={<CategoriesContent />} />
      <Route path="/signupverification" element={<Signupverification />} />
      <Route path="/codeverification" element={<Codeverification email={email} />} />
      <Route path="/confirmnewpassword" element={<ConfirmNewPassword email={email} />} />
      <Route exact path="/profile/*" element={<Profile user={props.user} />} />
      <Route exact path="contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/checkout" element={<Checkout user={props.user} />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/filter" element={<FilterPage />} />
    </Routes>
  );
};

export default Homerouting;
