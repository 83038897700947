import React from "react";

export default function Summary({ items, total }) {
  console.log("Cart Items is", items);

  return (
    <>
      <div>
        <h4 className="text-center mt-3">
          Order <br /> Summary
        </h4>
        <div className="order-summary">
          {/* <div className="clearfix">
            <p className="left">Shipping</p>
            <p className="right">$90</p>
          </div>
          <hr /> */}
          {/* <div className="clearfix">
            <p className="left">Shipping</p>
            <p className="right">$90</p>
          </div> */}
          {/* <hr /> */}

          <table className="total-table">
            <tr>
              <td>
                <p className="left">Total</p>
              </td>
              <td>
                <p className="right">${total}</p>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <div className="pop-up-buttom button">
            <button type="button" className="btn summary-btn" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@fat">
              Check your Items
            </button>
          </div>

          <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4>List of items</h4>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="container">
                  <div className="row mt-3">
                    {items?.map((item, id) => (
                      <>
                        <div className="col-md-6">
                          <div>
                            <img src={item.image} alt="images" className="img-fluid" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p>Name: {item.name}</p>
                          <p>Quantity: {item.quantity}</p>
                          <p>Price: $ {item.price} </p>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
                <div className="modal-footer button mt-3">
                  <button type="button" className="btn" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
