import React, { useState, useEffect } from "react";
import axios from "axios";
import Parse from "html-react-parser";
import ReturnLoader from "./Loader";

const Return = () => {
  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/refund/get`,
    })
      .then((res) => {
        setTerms(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="terms-main">
      <div className="container">
        <div className="terms-body">
          {loading ? (
            <ReturnLoader />
          ) : (
            <>
              <h3>{terms[0]?.title}</h3>
              {Parse(terms[0]?.description ? terms[0].description : "")}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Return;
