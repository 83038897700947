import React from "react";
import Skeleton from "@mui/material/Skeleton";

export default function OrderLoader() {
  return (
    <>
      <tr>
        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>
      </tr>

      <tr>
        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>
      </tr>

      <tr>
        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>

        <td>
          <Skeleton variant="text" />
        </td>
      </tr>
    </>
  );
}
