import axios from "axios";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import featurelist from "./Featurelist";
import { useDispatch } from "react-redux";
import Skeletoncard from "../skeletoncard/skeletoncard";
import { addToCart } from "../../../Services/Action/Action";
import { addToWishlist } from "../../../Services/Action/WishlistAction";
import { useSnackbar } from "notistack";

const FeaturedProducts = (get) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handler = (e, get) => {
    dispatch(addToCart(get));
    enqueueSnackbar("Item added to cart", {
      variant: "success",
    });
  };
  const handlerWish = (e, get) => {
    e.preventDefault();
    dispatch(addToWishlist(get));
    enqueueSnackbar("Item added to wishlist", {
      variant: "success",
    });
    console.log(get.price);
  };

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/product/getFeature`,
    })
      .then((res) => {
        console.log(res.data.products.data, "first product");
        let response = res.data.products.data;
        setProducts(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);

  return (
    <div className="feature-main">
      <div className="container">
        <div className="main-topic">
          <p>
            Our <span> Products</span>
          </p>
        </div>
        <div className="feature-products">
          <div className="row">
            {loading ? (
              <>
                <Skeletoncard />
              </>
            ) : (
              products.slice(0, 19).map((get, id) => {
                return (
                  <div className="col-md-4 col-lg-3 col-sm-6" key={id}>
                    <div className="cart-main">
                      <div className="feature-img">
                        <NavLink to={`/product/${get.slug}`}>
                          <img src={get.image} alt="featureproducts" />
                        </NavLink>
                      </div>
                      <div className="feature-data">
                        <NavLink to={`/product/${get.slug}`}>
                          <p className="cart-content-name">{get.name}</p>
                        </NavLink>
                        {/* <p className="cart-content-price">$ {get.price}</p> */}
                      </div>
                      {/* <div className="cart_icons">
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="cart-hand d-grid">
                              <button
                                type="button"
                                className="btn btn-sm btn-block"
                                onClick={(e) => handler(e, get)}
                              >
                                {" "}
                                <i class="fas fa-shopping-cart pe-1"></i>
                                ADD TO CART
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProducts;
