import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logomain.png";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const Navbar = (props) => {
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const counts = useSelector((state) => state.cardItems.totalCounts);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [keyword, setKeyword] = useState("");

  const wishCount = useSelector((state) => state.cardWished.totalCounts);

  // console.log(wishCount);

  const searchProduct = (e) => {
    e.preventDefault();
    navigate("/filter", {
      state: {
        keyword: keyword,
      },
    });
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/category/get`,
    })
      .then((res) => {
        console.log(res.data.data, "slug data");
        let response = res.data.data;
        setCategories(response);
      })
      .catch((err) => {
        console.log(err, "error message");
      });
  }, []);

  const logout = () => {
    // axios({
    //   method: "get",
    //   url: `${process.env.REACT_APP_API_URL}/user/logout`,
    //   headers: {
    //     Authorization: `Bearer ${props.user?.access_token}`,
    //   },
    // }).then((res) => {
    //   if (res.data.status) {
    //     props.setUser(null);
    //     localStorage.setItem("user", null);
    //     navigate("/", { replace: true });
    //   }
    // });

    props.setUser(null);
    localStorage.setItem("user", null);
    navigate("/", { replace: true });
    enqueueSnackbar("You have been logged out", {
      variant: "success",
    });
  };

  return (
    <>
      <div className="top_banner">
        {/* <div className=" header_contents container">
          <h1>free shiping in orders over $69 to usa (48 state)</h1>
        </div> */}
      </div>
      <div className="top_header">
        <div className="mid_header">
          <div className="container">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-lg-2 col-md-2 col-sm-12 d-flex justify-content-center">
                <div className="logo">
                  <Link to="/">
                    <img src={Logo} alt="logo" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <form onSubmit={searchProduct}>
                  <div className="search_field">
                    <div class="input-group">
                      <input type="text" className="form-control" placeholder="Search for products" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                      <span class="input-group-text">
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              {/* <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="icons ">
                {props.user === null ? (
                  <>
                    <span className="cart-div">
                      <NavLink to="/wishlist" className="link-cart">
                        <i class="fas fa-heart">
                          <sup className="cart-sup">{wishCount}</sup>
                          <span>wishlist</span>
                        </i>
                      </NavLink>
                      <NavLink to="/cart" className="link-cart">
                        <i class="fas fa-shopping-cart">
                          <sup className="cart-sup">{counts}</sup>
                          <span>cart</span>
                        </i>
                      </NavLink>
                    </span>
                  </>
                ) : (
                  <div className="profile">
                    <NavLink to="/wishlist" className="link-cart">
                      <i class="fas fa-heart">
                        <sup className="cart-sup">{wishCount}</sup>
                      </i>
                    </NavLink>
                    <NavLink to="/cart" className="link-cart">
                      <i class="fas fa-shopping-cart">
                        <sup className="cart-sup">{counts}</sup>
                      </i>
                    </NavLink>
                  </div>
                )}
              </div>
            </div> */}
            </div>
          </div>
        </div>

        <div className="bottom_header">
          <nav className="navbar navbar-expand-lg " id="navbar_top">
            <div className="container-fluid">
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon">=</span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="category_navbar mb-2 mb-lg-0">
                  <li className="nav-item">
                    <select className="select" aria-label="Default select example" onChange={(e) => navigate(`/category/${e.target.value}`)}>
                      <option>
                        {" "}
                        <span className="span-menu">=</span> All Categories
                      </option>

                      {categories.map((category) => (
                        <>
                          <option value={category.slug}>{category.name}</option>
                        </>
                      ))}
                    </select>
                  </li>
                </ul>

                {/* Right Menu */}

                <div className="navbar-nav right-nav-items ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink to="/" className="nav-link">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/about" className="nav-link">
                      About
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink to="/termsandconditions" className="nav-link">
                      Terms & Conditions
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/contact" className="nav-link">
                      Contact
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                    <div className="icons">
                      <div className=" nav-link">
                        {props.user === null ? (
                          <>
                            <NavLink to="/login" className="linked">
                              <span className="span-sign">
                                <i class="fas fa-user"></i>Sign in
                              </span>
                            </NavLink>

                            <NavLink to="/signup" className="linked">
                              <span className="span-sign">
                                {" "}
                                <i class="fa-solid fa-arrow-right-to-bracket"></i>
                                Sign up
                              </span>
                            </NavLink>
                          </>
                        ) : (
                          <div className="profile">
                            <NavLink to="/profile" className="linked">
                              <span className="span-sign">
                                <i class="fas fa-user"> </i>Profile
                              </span>
                            </NavLink>{" "}
                            <span>/</span>
                            <a className="linked" onClick={logout}>
                              <span className="span-sign">Logout</span>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </li> */}
                </div>
              </div>
              {/* <div className="navbar-nav1 ">
                <li className="nav-item">
                  <div className="icons">
                    <div className=" nav-link">
                      {props.user === null ? (
                        <>
                          <NavLink to="/login" className="linked">
                            <span className="span-sign">
                              <i class="fas fa-user"></i>Sign in
                            </span>
                          </NavLink>
                          <i class="fa-solid fa-arrow-right-to-bracket"></i>
                          <NavLink to="/signup" className="linked">
                            <span className="span-sign">Sign up</span>
                          </NavLink>
                        </>
                      ) : (
                        <div className="profile">
                          <NavLink to="/profile" className="linked">
                            <span className="span-sign">
                              <i class="fas fa-user"> </i>Profile
                            </span>
                          </NavLink>{" "}
                          <span>/</span>
                          <a className="linked" onClick={logout}>
                            <span className="span-sign">Logout</span>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              </div> */}
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};
export default Navbar;
