export const addToCart = (data, count) => {
  return (dispatch) => {
    // dispatch(showSuccessSnackbar("Item added to cart"));

    return dispatch({
      type: "ADD_TO_CART",
      data: data,
      count: count,
    });
  };
};

export const removeToCart = (id) => {
  return {
    type: "REMOVE_TO_CART",
    data: id,
  };
};

export const clearCart = () => {
  return {
    type: "CLEAR_CART",
  };
};

export const addQuantity = (id) => {
  return {
    type: "ADD_QUANTITY",
    data: id,
  };
};

export const subQuantity = (id) => {
  return {
    type: "SUB_QUANTITY",
    data: id,
  };
};
